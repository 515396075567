import Location from '@/Interfaces/Location';

export class CreateCustomerRequest {
  public id?: string;
  public first_name?: string;
  public last_name?: string;
  public account_number?: string;
  public email?: string;
  public company_name?: string;
  public address?: string;
  public city?: string;
  public state?: string;
  public zip?: string;
  public home_phone?: string;
  public cell_phone?: string;
  public office_phone?: string;
  public office_ext_phone?: string;
  public contact_balance?: string | number;
  public email_trx_receipt?: string;
  public header_message_type_id?: string;
  public header_message?: string;
  public contact_api_id?: string;
  public parent_id?: string;
  public location_id?: string;
  public location?: any;
}
