
import router from '@/router';
import ContactService from '../services/ContactService';
import LocalStorageService from '../services/LocalStorageService';
import RoleService from '@/services/RoleService';
import { CreateCustomerRequest } from '@/contracts/CreateCustomerReqest';
import { PaymentType } from '@/enums/PaymentType';
import { TransactionType } from '@/enums/TransactionType';

import { isEqual } from 'lodash';
import { toRaw } from 'vue';
import { defineComponent } from 'vue';

interface ComponentData {
  customerErrors: any;
  isPopupBlock: string;
  contactService: ContactService;
  localStorageService: LocalStorageService;
  roleService: RoleService;
  showHistoryFlag: boolean;
  customer: CreateCustomerRequest;
  saveFormDisable: boolean;
  customerError: boolean;
  locs: any[];
  disableFields: boolean;
  breadcrumbs: {
    text: string;
    href?: string;
    active?: boolean;
  }[];
  customerOrginalRef: any;
  title: string;
  changes: any[];
  loading: boolean;
  messageText: string;
  selectedLocations: any[];
  locations: any[];
  isSuperadminLoc: boolean;
  selectedLocationId: string;
  locationOptions: { value: any; text: string }[];
  customerHistory: any[];
  transactionType: TransactionType;
  pageCount: number;
  pageFlag: boolean;
  alertTitle: string;
  alertMessage: string;
  showConfirm: boolean;
  setDiscardChangesModal: boolean;
  showConfirmDiscardChanges: boolean;
}

export default defineComponent({
  name: 'AddContact',
  data() {
    const data: ComponentData = {
      customerErrors: {},
      isPopupBlock: 'none',
      contactService: undefined,
      localStorageService: undefined,
      roleService: undefined,
      showHistoryFlag: true,
      customer: undefined,
      saveFormDisable: false,
      customerError: false,
      locs: [],
      disableFields: false,
      breadcrumbs: [
        {
          text: 'Contact',
          href: '#/contacts',
        },
        {
          text: 'Add Contact',
          active: true,
        },
      ],
      customerOrginalRef: undefined,
      title: 'Create Contact',
      changes: [],
      loading: false,
      messageText: '',
      selectedLocations: [],
      locations: [],
      isSuperadminLoc: false,
      selectedLocationId: null,
      locationOptions: [{ value: null, text: 'Please select a location' }],
      customerHistory: [],
      transactionType: undefined,
      pageCount: 1,
      pageFlag: true,
      alertTitle: '',
      alertMessage: '',
      showConfirm: false,
      setDiscardChangesModal: false,
      showConfirmDiscardChanges: false,
    };
    return data;
  },
  created() {
    this.contactService = new ContactService();
    this.localStorageService = new LocalStorageService();
    this.roleService = new RoleService(new LocalStorageService());
    this.customer = new CreateCustomerRequest();
  },
  async mounted() {
    this.transactionType = TransactionType;
    this.loading = true;
    this.isSuperadminLoc = false;
    const locationOfSuperadmin = this.localStorageService.getItem('locations');
    this.selectedLocationId =
      this.$route.query.locationId?.toString() ||
      this.localStorageService.getItem('primary_location_id');
    this.customer.location_id = this.selectedLocationId;
    this.customerOrginalRef = { ...this.customer };
    if (this.isEditMode) {
      this.title = this.title.replace('Create Contact', 'Edit Contact');
      this.breadcrumbs[1].text = 'Edit Contact';
      if (
        locationOfSuperadmin &&
        locationOfSuperadmin.slice(2, -2) !== this.selectedLocationId &&
        this.roleService.isSuperadmin
      ) {
        this.isSuperadminLoc = true;
      }
    }

    this.disableFields = !(this.isEditMode || this.isCreateMode);

    if (this.contactId) {
      const contactPromiss = await this.contactService.getContactById(
        this.contactId,
        this.selectedLocationId,
      );
      this.customer = contactPromiss.data.customer;
      this.customerOrginalRef = { ...this.customer };
      this.selectedLocationId = contactPromiss.data.customer.location_id;
    } else {
      this.selectedLocationId = this.localStorageService.getItem(
        'primary_location_id',
      );
    }

    await this.getLocations();
    this.loading = false;
  },
  methods: {
    handleScroll() {
      const scrollHeight = document.getElementById('scrollDiv')?.scrollHeight;
      const clientHeight = document.getElementById('scrollDiv')?.clientHeight;
      const scrollTop = document.getElementById('scrollDiv')?.scrollTop;
      let scrollHeightNumber = 0;
      let scrollTopNumber = 0;
      if (scrollHeight && clientHeight && scrollTop) {
        scrollHeightNumber = scrollHeight - clientHeight;
        scrollTopNumber = scrollTop;
      }

      const topNeve = document.getElementById('scrollDiv');
      if (this.pageFlag && topNeve) {
        if (
          scrollTopNumber > scrollHeightNumber - 10 &&
          scrollHeightNumber != 0
        ) {
          this.pageFlag = false;
          this.pageCount++;
          this.showHistory();
        }
      }
    },

    async getLocations() {
      await this.$store.dispatch('getLocationList');
      this.locs = this.$store.getters.allLocationList;
      this.setLocationOptions();
    },

    setLocationOptions() {
      this.locationOptions = this.locationOptions.concat(
        this.locs.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
    },

    async changeLocation() {
      this.localStorageService.store(
        'primary_location_id',
        this.selectedLocationId,
      );
      this.customer.location_id = this.selectedLocationId;
      const filter = this.$store.getters.contactFilter;
      filter.search = '';
      filter.locationId = this.customer.location_id;
      await this.$store.dispatch('setContactFilter', filter);
    },

    enableEdit() {
      router.push({
        path: `/contacts/${this.contactId}/edit`,
        query: { locationId: this.selectedLocationId },
      });
    },

    handleCancel() {
      if (!this.disableFields && this.setDiscardChangesModal) {
        this.showConfirmDiscardChanges = true;
        return;
      }
      this.navigateAway();
    },

    navigateAway() {
      router.push({ path: `/contacts` });
    },

    confirmationOk() {
      this.setDiscardChangesModal = false;
      router.push({ path: `/contacts` });
    },

    async handleSave() {
      this.customerErrors = {};
      this.loading = true;
      if (this.contactFormValidation()) {
        try {
          if (this.customer.cell_phone === '') delete this.customer.cell_phone;
          if (this.customer.home_phone === '') delete this.customer.home_phone;
          if (this.customer.office_phone === '')
            delete this.customer.office_phone;
          if (this.customer.zip === '') delete this.customer.zip;
          if (this.customer.contact_balance === '') {
            delete this.customer.contact_balance;
          } else {
            this.customer.contact_balance = Number(
              this.customer.contact_balance,
            );
          }

          await this.contactService.saveContact(
            {
              ...this.customer,
              email_trx_receipt: this.customer.email_trx_receipt ? '1' : '0',
            },
            this.isEditMode,
            this.contactId,
            this.selectedLocations,
          );

          this.loading = false;
          this.alertTitle = 'success';
          this.alertMessage = this.isEditMode
            ? 'Contact updated successfully'
            : 'Contact added successfully';
          this.showConfirm = true;
          this.setDiscardChangesModal = false;

          setTimeout(() => {
            router.push({ path: `/contacts` });
          }, 1500);
        } catch (e) {
          this.loading = false;
          if (e.response.data.errors === null) {
            this.customerError = true;
            this.messageText =
              'Could not create new customer. Please try again!';
            this.alertTitle = 'error';
            this.alertMessage = this.messageText;
            this.showConfirm = true;
          } else {
            e.response.data.errors.forEach((error: any) => {
              const nameProperty = error.property;
              const newObject = Object.assign(this.customerErrors, {
                [nameProperty]: error.message,
              });
              this.customerErrors = newObject;
            });
          }
        }
      } else {
        this.loading = false;
        this.customerError = true;
      }
    },

    contactFormValidation() {
      if (
        this.customer.last_name &&
        (!Object.prototype.hasOwnProperty.call(this.customer, 'last_name') ||
          this.customer.last_name.trim() === '')
      ) {
        return false;
      }

      return true;
    },

    showHistoryFnc() {
      if (this.showHistoryFlag) {
        this.pageCount = 1;
        this.customerHistory = [];
        this.showHistory();
      } else {
        this.showHistoryFlag = true;
      }
    },

    async showHistory() {
      this.loading = true;
      this.showHistoryFlag = false;
      const res = await this.contactService.getCustomerHistory(
        this.contactId,
        this.pageCount,
        150,
      );
      if (res.status === 200 || res.status === 201) {
        this.loading = false;
        this.customerHistory = [...this.customerHistory, ...res.data.orders];
        this.pageFlag = true;
      } else {
        this.loading = false;
        this.customerError = true;
        this.messageText = 'Could not create new customer. Please try again!';
        this.alertTitle = 'error';
        this.alertMessage = this.messageText;
        this.showConfirm = true;
      }
    },

    displayNegativeCurrency(cellValue: number) {
      return {
        negative: cellValue < 0,
      };
    },

    getPriceValue(value: any) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return value === null || Number.isNaN(value)
        ? '-'
        : formatter.format(value);
    },

    getPaymentType(type: number) {
      if (type === PaymentType.Cash) return 'Cash';
      else if (type === PaymentType.CreditCard) return 'Credit Card';
      else if (type === PaymentType.None) return 'None';
      else return '';
    },

    positiveButton() {
      this.showConfirm = false;
    },

    negativeButton() {
      this.showConfirm = false;
    },

    numberValidation($event: any) {
      // Only 0-9 numbers are allowed. No Spaces, charactors or special charactors are allowed
      if ($event.keyCode === 32) {
        $event.preventDefault();
        return false;
      } else if ($event.keyCode > 47 && $event.keyCode < 58) {
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    },

    isInputNumber(event: any) {
      const char = String.fromCharCode(event.which);
      if (!/[0-9|.|-]/.test(char)) {
        event.preventDefault();
      }
    },
  },
  computed: {
    isEditMode() {
      return (
        this.contactId !== undefined &&
        this.contactId !== null &&
        this.$route.name.toString().includes('edit')
      );
    },

    contactId() {
      return this.$route.params.id?.toString() ?? null;
    },

    isCreateMode() {
      return (
        this.contactId === undefined ||
        (this.contactId === null && this.$route.name.toString().includes('new'))
      );
    },
  },
  watch: {
    customer: {
      handler() {
        if (!isEqual(toRaw(this.customer), this.customerOrginalRef)) {
          this.setDiscardChangesModal = true;
        } else {
          this.setDiscardChangesModal = false;
        }
      },
      deep: true,
    },
  },
});
